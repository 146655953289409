<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.name" placeholder="" />
        <small v-if="errors && errors.name" class="name_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Description<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.description" type="text" />
        <small v-if="errors && errors.description" class="text-danger">
          {{ errors.description[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Active<sup>*</sup> </label>
      <div class="form-group__content ">
        <input type="checkbox" class="form-check" v-model="form.is_active" />
        <small v-if="errors && errors.is_active" class="text-danger">
          {{ errors.is_active[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Submitting..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PaymentMethodForm",
  props: {
    paymentMethodId: String
  },
  data() {
    return {
      paymentMethod: null,
      form: {
        name: '',
        is_active: false,
        description: ''
      },
      show: true,
      isLoading: false,
      message: null,
      errors: {
        name: [],
        is_active: [],
        description: []
      }
    }
  },
  async created() {
    if (this.paymentMethodId) {
      axios.get('payment-methods/' + this.paymentMethodId).then((response) => {
        this.paymentMethod = response.data;
        this.form.name = this.paymentMethod.name;
        this.form.is_active = this.paymentMethod.is_active;
        this.form.description = this.paymentMethod.description;
      });
    }
  },
  methods: {
    async onSubmit() {
      if (this.paymentMethodId) {
        await this.updatePaymentMethod()
      } else {
        await this.createPaymentMethod()
      }
    },
    createPaymentMethod() {
      this.isLoading = true;
      axios.put('payment-methods/', this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_payment_methods'})
        }, 1000)
      }).catch((error) => {
        if (error.response.status === 422) {
          this.message = "Payment method could not be created. Please enter all required fields.";
          this.errors = error.response.data.errors
        } else {
          this.message = error.backendErrors[0]
        }
        this.isError = true
        this.isLoading = false;
      });
    },
    updatePaymentMethod() {
      this.isLoading = true;
      axios.put('payment-methods/' + this.paymentMethodId, this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_payment_methods'})
        }, 1000)
      }).catch((error) => {
        if (error.response.status === 422) {
          this.message = "Payment method could not be updated. Please enter all required fields.";
          this.errors = error.response.data.errors
        } else {
          this.message = error.backendErrors[0]
        }
        this.isError = true
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>