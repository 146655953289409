<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Payment Method</h3>
    </div>

    <!-- Content Row -->
    <PaymentMethodForm :payment-method-id="$route.params.id"></PaymentMethodForm>

  </div>
</template>

<script>
import PaymentMethodForm from "./PaymentMethodForm";
export default {
  name: "EditPaymentMethod",
  components: {PaymentMethodForm},
}
</script>

<style scoped>

</style>